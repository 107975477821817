import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Table } from "react-bootstrap";
import { format, parse } from "date-fns";
import { path } from "../../Router/Routes";
import classes from "../productDetail/ProductDetail.module.css";
import customs from "./Payment.module.css";
import styles from "../order/Order.module.css";
import beauty from "../Ledger/Ledger.module.css";
import CustomDatePicker from "../../reusableComponents/CustomDatePicker/CustomDatePicker";
import Header from "../../reusableComponents/Header";
import Loader from "../../reusableComponents/Loader/Loader";
import DropdownWithID from "../../reusableComponents/Input/DropdownWithID";
import DropdownInput from "../../reusableComponents/Input/DropDownInput";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import {
  getBankList,
  getCustOMERPendingInvoiceList,
  setPendingInvoiceList,
  updatePEndingInvoices,
} from "../../Store/Customer/CustomerActions";

const Payment = () => {
  const { id, locationid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const locationById = useSelector((state) => state.store.locationById);
  const profileData = useSelector((state) => state.orderList.profileData);
  const [paymentMethod, setPaymentMethod] = useState("Cheque");
  const [chequeNo, setChequeNo] = useState("");
  const [amount, setAmount] = useState("");
  const [chequeDate, setchequeDate] = useState(
    format(new Date(), "dd/MM/yyyy")
  );
  const [bank, setBank] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [indianBanks, setIndianBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [invoiceList, setInvoiceList] = useState([]);
  const [pendingInvoice, setPendingInvoice] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState(amount);
  const [displayBalance, setDisplayBalance] = useState(0);

  const handleCheckboxChange = (event, isChecked, invoice) => {
    if (displayBalance === 0 && isChecked && invoice.CrDrDesc === "Dr") {
      event.preventDefault();
      toast.error(
        "You cannot select more invoices as the balance amount is zero."
      );
      return;
    }

    let newBalance = balanceAmount;
    let updatedInvoice = { ...invoice };

    if (isChecked) {
      // setSelectedInvoice([...selectedInvoice, invoice]);

      if (invoice.CrDrDesc === "Cr") {
        updatedInvoice.pPayRcptAmt = Number(invoice.CreditAmt);
        updatedInvoice.VoucherAmountCrDr = Math.abs(Number(balanceAmount));
        updatedInvoice.Amount = balanceAmount;
        updatedInvoice.AmountCrDr = balanceAmount;
        updatedInvoice.PayRcptAmt = Number(invoice.CreditAmt);
        console.log(typeof newBalance);
        console.log(typeof invoice.CreditAmt);

        newBalance = Number(newBalance) + Number(invoice.CreditAmt);
      } else if (invoice.CrDrDesc === "Dr") {
        updatedInvoice.pPayRcptAmt = Math.min(
          balanceAmount,
          Number(invoice.CreditAmt)
        );
        updatedInvoice.VoucherAmountCrDr = -Math.abs(Number(balanceAmount));
        updatedInvoice.Amount = balanceAmount;
        updatedInvoice.AmountCrDr = balanceAmount;
        updatedInvoice.PayRcptAmt = Math.min(
          balanceAmount,
          Number(invoice.CreditAmt)
        );

        newBalance -= Number(invoice.CreditAmt);
      }

      setSelectedInvoice([...selectedInvoice, updatedInvoice]);
    } else {
      setSelectedInvoice(selectedInvoice.filter((inv) => inv !== invoice));

      if (invoice.CrDrDesc === "Cr") {
        newBalance -= Number(invoice.CreditAmt);
      } else if (invoice.CrDrDesc === "Dr") {
        newBalance += Number(invoice.CreditAmt);
      }
    }

    setBalanceAmount(newBalance);

    setDisplayBalance(Math.max(0, newBalance));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPendingInvoice([]);
    setBalanceAmount(0);
    setDisplayBalance(0);
    setAmount("");
    setChequeNo("");
  };

  const buttonClick = () => {
    if (B2BauthToken) {
      if (!chequeNo) {
        toast.error("Please enter the cheque Number");
      } else if (!amount) {
        toast.error("Please enter the cheque amount");
      } else {
        setBtnLoading(true);
        dispatch(setPendingInvoiceList(B2BauthToken, id)).then((result) => {
          if (result.status === "success") {
            setBtnLoading(false);
            setShowModal(true);
            setPendingInvoice(result.data.data);
          } else {
            setBtnLoading(false);
          }
        });
      }
    } else {
      toast.error("Please Login First!");
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setBalanceAmount(e.target.value);
    setDisplayBalance(e.target.value);
  };

  const adjustPendingInvoices = () => {
    const parsedDate = parse(chequeDate, "dd/MM/yyyy", new Date());
    const formattedChequeDate = parsedDate.toISOString();

    let data = {
      Amount: amount,
      Cash: false,
      CashAmt: 0,
      Cheque: paymentMethod === "Cheque",
      ChequeAmt: amount,
      ChequeDate: formattedChequeDate,
      ChequeNo: chequeNo,
      CreditCard: false,
      CreditCardAmt: 0,
      CreditCardDate: null,
      CreditCardNo: null,
      DraftMode: 0,
      FKBankChequeID: bank,
      FKBankCreditCardID: null,
      FKInterestAcID: 16,
      FKPartyID: profileData?.customerId,
      FKRebateAcID: 0,
      FKReferByID: 0,
      FKSalesPerID: "1",
      FKSeriesID: locationById?.b2bPKOrderSeriesId,
      HdrImages: [],
      Interest: 0,
      InterestAccount: null,
      IsPDCApplied: true,
      NetAmt: 0,
      PayRcptAmt: amount,
      Rebate: 0,
      RebateAccount: null,
      Remarks: "",
      TransDetail: selectedInvoice.map((invoice) => ({
        ...invoice,
        Selected: true,
      })),
    };

    dispatch(updatePEndingInvoices(id, data)).then((result) => {
      if (result.status === "success") {
        toast.success("Receipt adjusted successfully!");
        handleCloseModal();
      }
    });
  };

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
    dispatch(getBankList(id)).then((result) => {
      if (result.status === "success") {
        setIndianBanks(result.data);
      }
    });

    setLoading(true);
    dispatch(getCustOMERPendingInvoiceList(B2BauthToken, id)).then((result) => {
      if (result.status === "success") {
        setInvoiceList(result.data);
        setLoading(false);
      }
    });
  }, [B2BauthToken, dispatch, id, locationid, navigate]);

  return (
    <>
      <Header pagename={"Payments"} />

      <div className={`${classes.PaymentPageWrapper}`}>
        <div className={`${customs.PaymentFiltersColumn}`}>
          <div className={`${customs.PaymentFiler}`}>
            <div className={`${customs.PaymentMthod}`}>
              <label className={`${styles.dateText}  `}>Payment Mode:</label>
              <DropdownInput
                value={paymentMethod}
                name={"Status"}
                page={"payment"}
                optionName={"Status"}
                setCategoryHandler={(e) => setPaymentMethod(e.target.value)}
                categoryList={[
                  { value: "Cheque", label: "Cheque" },
                  { value: "UPI", label: "UPI" },
                ]}
              />
            </div>
            {paymentMethod === "Cheque" && (
              <>
                <div className={`${customs.PAYMENTFields}`}>
                  <div
                    className={`${customs.PaymentHistorySearch} ${customs.chequno}`}
                  >
                   
                    <input
                      type="text"
                      value={chequeNo}
                      className="search"
                      placeholder={`Enter cheque number`}
                      onChange={(e) => setChequeNo(e.target.value)}
                    />
                  </div>

                  <div className={`${customs.PaymentHistorySearch}`}>
                   
                    <input
                      type="number"
                      value={amount}
                      className="search"
                      placeholder={`Enter Amount`}
                      onChange={handleAmountChange}
                    />
                  </div>
                </div>

                <div className={`${styles.OrderHistoryFilterOptions}`}>
                  <div className={`${styles.OrderDates}`}>
                    <label
                      className={`${styles.dateText}  `}
                      htmlFor="chequeDate"
                    >
                      Cheque Date:
                    </label>

                    <CustomDatePicker
                      selectedDate={chequeDate}
                      onChange={(date) => {
                        setchequeDate(date);
                      }}
                    />
                  </div>
                  <DropdownWithID
                    value={bank}
                    name={"Bank Name"}
                    optionName={"Bank Name"}
                    setCategoryHandler={(e) => setBank(e.target.value)}
                    categoryList={indianBanks}
                  />
                </div>
              </>
            )}
          </div>
          <div className={`${customs.PaymentAdjustButtons}`}>
            <button className={styles.UpdateBtn} onClick={() => buttonClick()}>
              {btnLoading ? <Loader /> : "Adjust Invoice"}
            </button>
          </div>
        </div>

        <div className={`${classes.PaymentList}`}>
          {loading === true ? (
            <div className={`${styles.PageWrapper}`}>
              <Loader />
            </div>
          ) : (
            <>
              {invoiceList && invoiceList.length ? (
                <div className={`${beauty.LedgerDetailTable}`}>
                  <table
                    className={`${classes.TableComponent} ${beauty.LedgerTable}`}
                  >
                    <thead className={`${classes.TableComponentHeader}`}>
                      <tr>
                        <th className={`${classes.TableNum} `} scope="col">
                          ENTRY NO.
                        </th>
                        <th className={`${classes.TableNum} `} scope="col">
                          NARRATION
                        </th>
                        <th
                          className={`${classes.TableNum} text-center`}
                          scope="col"
                        >
                          ENTRY DATE
                        </th>

                        <th
                          className={`${classes.TableNum} text-end`}
                          scope="col"
                        >
                          NET AMOUNT
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceList.map((product, index) => (
                        <tr key={index}>
                          <td className={`${classes.TableNum} `}>
                            {product.Series + " " + product.EntryNo}
                          </td>
                          <td className={`${classes.TableNum}`}>
                            {product.TranName}
                          </td>
                          <td className={`${classes.TableNum} text-center`}>
                            {" "}
                            {new Date(product.EntryDate)
                              .toLocaleDateString("en-GB")
                              .replace(/\//g, "-")}
                          </td>

                          <td className={`${classes.TableNum} text-end`}>
                            {product.NetAmt.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className={`${classes.NoImageContainer}`}>
                  <h6>Data not found.</h6>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="adjustViewModal"
      >
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={styles.modalTitle}>Invoice List</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${beauty.CreditContent} modal-body-scrollable`}>
          <div className={` ${customs.ReceiptlistTable}`}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th className={`${customs.tableSmall}`}>Series No</th>
                  <th className={`${customs.tableWidth}`}>Entry Date</th>
                  <th className={`${customs.tableWidth}`}>Due Date</th>
                  <th className={`${customs.tableSmall}`}>CrDrDesc</th>
                  <th className={`${customs.tableSmall}`}>Net Amt</th>
                  <th className={`${customs.tableSmall}`}>Due Amt</th>
                  <th className={`${customs.tableSmall}`}>Credit Amt</th>
                  <th className={`${customs.tableWidth}`}>Trans Name</th>
                  <th className={`${customs.tableSmall}`}>Trade Disc</th>
                  <th className={`${customs.tableSmall}`}>Lot Disc</th>
                  <th className={`${customs.tableWidth}`}>Scheme Disc</th>
                  <th className={`${customs.tableSmall}`}>Cash Disc</th>
                  <th className={`${customs.tableWidth}`}>Received Amt</th>
                </tr>
              </thead>
              <tbody className={`${customs.tablebody}`}>
                {pendingInvoice.map((invoice, index) => (
                  <tr key={index}>
                    <td
                      onChange={(e) =>
                        handleCheckboxChange(e, e.target.checked, invoice)
                      }
                    >
                      <input type="checkbox" />
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.SeriesNoDesc}
                    </td>
                    <td className={`${customs.tableWidth}`}>
                      {" "}
                      {new Date(invoice.EntryDate)
                        .toLocaleDateString("en-GB")
                        .replace(/\//g, "-")}
                    </td>
                    <td className={`${customs.tableWidth}`}>
                      {new Date(invoice.DueDate)
                        .toLocaleDateString("en-GB")
                        .replace(/\//g, "-")}
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.CrDrDesc}
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.NetAmt.toFixed(2)}
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.DueAmt.toFixed(2)}
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.CreditAmt.toFixed(2)}
                    </td>

                    <td className={`${customs.tableWidth}`}>
                      {invoice.TranName}
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.TradeDisc.toFixed(2)}
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.LotDisc.toFixed(2)}
                    </td>
                    <td className={`${customs.tableWidth}`}>
                      {invoice.SchemeDisc.toFixed(2)}
                    </td>
                    <td className={`${customs.tableSmall}`}>
                      {invoice.CashDisc.toFixed(2)}
                    </td>
                    <td className={`${customs.tableWidth}`}>
                      {invoice.PayRcptAmt.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className={customs.UpdateFooter}>
            <p>Balance Amount:{(+displayBalance).toFixed(2)}</p>
            <button
              onClick={adjustPendingInvoices}
              className={styles.UpdateBtn}
            >
              OK
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Payment;
