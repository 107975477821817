import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import NoProducts from "../../Assessts/Images/NoProducs.png";
import noImg from "../../Assessts/Images/noImg.png";
import classes from "./ProductDetail.module.css";
import styles from "../order/Order.module.css";

import Header from "../../reusableComponents/Header";
import SelectInput from "../../reusableComponents/Input/SelectInput";
import DropdownInput from "../../reusableComponents/Input/DropDownInput";

import {
  getProductByStoreHandler,
  getStockDetails,
} from "../../Store/Product/ProductActions";
import { AddOrderListHandler } from "../../Store/OrderList/OrderListActions";
import { path } from "../../Router/Routes.js";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { productActions } from "../../Store/Product/ProductSlice";
import Loader from "../../reusableComponents/Loader/Loader";
import PaginationBox from "../../reusableComponents/Pagination/PaginationBox";

const ProductDetail = () => {
  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState("true");
  const [groupNameFilter, setGroupNameFilter] = useState("all");
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [activeProducts, setActiveProducts] = useState(0);
  const [inactiveProducts, setInactiveProducts] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [remark, setRemark] = useState("");
  const quantityInputRef = useRef(null);
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const productModalRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  let [uniqueGroupNames, setUniqueGroupNames] = useState([]);
  let [stockDetails, setStockDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
  }, [B2BauthToken, dispatch, id, locationid, navigate]);

  useEffect(() => {
    setLoading(true);
    const params = {
      ClientRegID: id,
      LocationID: locationid,
      page: currentPage,
      limit: limit,
      active: statusFilter,
      search: searchQuery,
      MfgGroupName: groupNameFilter,
    };

    const showStockDetails = () => {
      dispatch(getStockDetails(id, locationid)).then((result) => {
        setStockDetails(result.data);
      });
    };

    dispatch(getProductByStoreHandler(params)).then((result) => {
      if (result.status === "success") {
        if (searchQuery.length <= 1) {
          setProducts(result.data);
          dispatch(productActions.setProductListHandler(result.data));
        }
        setProducts(result.data);
        dispatch(productActions.setProductListHandler(result.data));
        setLoading(false);      
       setUniqueGroupNames(result.uniqueGroupNames);
        setTotalProducts(result.totalItems);
        setInactiveProducts(result.totalInActiveItems);
        setTotalPages(result.totalPages);
        setTotalItems(result.totalItems);
        setActiveProducts(result.totalActiveItems)
        showStockDetails();
      } else {
        setLoading(false);
        toast.error("An error occured. Please refresh your page.");
      }
    });
  }, [
    dispatch,
    id,
    locationid,
    currentPage,
    limit,
    statusFilter,
    searchQuery,
    groupNameFilter,
  ]);

  useEffect(() => {
    if (showModal && quantityInputRef.current) {
      quantityInputRef.current.focus();
      quantityInputRef.current.select();
    }
  }, [showModal]);

  const handleSearch = (e) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
    setSelectedIndex(-1);

    if (newSearchQuery.length > 1) {
      setIsDropdownOpen(true);
      const params = {
        ClientRegID: id,
        LocationID: locationid,
        page: currentPage,
        limit: limit,
        active: statusFilter,
        search: newSearchQuery,
        MfgGroupName: groupNameFilter,
      };
      dispatch(getProductByStoreHandler(params)).then((result) => {
        if (result.status === "success") {
          setSearchResults(result.data.slice(0, 4));
          setProducts(result.data);
        } else {
          setSearchResults([]);
        }
      });
    } else {
      setSearchResults([]);
    }
  };

  const handleKeyDown = (e) => {
    if (!isDropdownOpen) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setSelectedIndex((prevIndex) =>
          prevIndex < searchResults.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
        break;
      case "Enter":
        e.preventDefault();
        if (selectedIndex >= 0 && selectedIndex < searchResults.length) {
          handleDropdownItemClick(searchResults[selectedIndex]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (searchResults.length > 0) {
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
    }
  }, [searchResults]);

  const handleDropdownItemClick = (item) => {
    setSearchQuery(item.NameToDisplay);
    setIsDropdownOpen(false);
  };

  const handleStatusFilter = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleGroupNameFilter = (e) => {
    setGroupNameFilter(e.target.value);
  };

  const getStockForProduct = (productPKID) => {
    const stockArray = stockDetails.filter(
      (stock) => stock.product_PKID === productPKID.toString()
    );

    if (stockArray.length > 0) {
      return stockArray[0]?.totalStock;
    } else {
      return 0;
    }
  };

  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;

    return `${day} ${month} ${year} ${hours12}:${padZero(minutes)} ${ampm}`;
  }

  function padZero(num) {
    return (num < 10 ? "0" : "") + num;
  }

  const filteredProducts = products.filter((product) => {
    const groupNameFilterMatch =
      groupNameFilter === "all" ||
      (groupNameFilter === "Not Listed" && !product?.MfgGroupName) ||
      product?.MfgGroupName === groupNameFilter;

    const searchMatch = product.ERPProductName.toLowerCase().includes(
      searchQuery.toLowerCase()
    );

    return groupNameFilterMatch && (searchQuery.length <= 1 || searchMatch);
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
    setQuantity(1);
    setRemark("");
  };

  const openOrderlistPopup = () => {
    setShowModal(true);
    const productModal = productModalRef.current;
    if (productModal) {
      const bsModal = window.bootstrap.Modal.getInstance(productModal);
      if (bsModal) {
        bsModal.hide();
      }
    }
  };

  const handleQuantityChange = (e) => {
    setQuantity(Number(e.target.value));
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  const addToOrderList = (product) => {
    if (B2BauthToken) {
      const newProduct = {
        ProductId: product._id,
        ProductPKID: product.PKID,
        ProductPrice: product.SaleRate,
        ProdutQty: quantity,
        Ptr: product.Ptr,
        Remark: remark,
        LocationId: locationid,
      };
      const orderdetail = {
        CustomerId: id,
        StoreId: id,
        lineItems: [newProduct],
      };

      dispatch(AddOrderListHandler(B2BauthToken, orderdetail)).then(
        (result) => {
          if (result.status === "success") {
            toast.success("Product added successfully!");
          }
        }
      );
    } else {
      alert("Please login first!");
    }
  };

  const handleAddToOrderList = (e) => {
    e.preventDefault();

    addToOrderList(selectedProduct);
    handleCloseModal();
  };

console.log("totalProducts", totalProducts)
console.log("inactiveProducts", inactiveProducts)
console.log("activeProducts", activeProducts)


  return (
    <>
      <Header pagename={"Product List"} />
      <div className={`${classes.ProductDetailWrapper}`}>
        <div className={`${classes.ProductDetailTop}`}>
          <div className={`${classes.ProductTopCards}`}>
            <div className={`${classes.ProductTopComponent}`}>
              <h2>{totalProducts}</h2>
              <p>Total Products</p>
            </div>
            <div className={`${classes.ProductTopComponent}`}>
              <h2>{inactiveProducts}</h2>
              <p>Inactive Products</p>
            </div>
            <div className={`${classes.ProductTopComponent}`}>
              <h2>{activeProducts}</h2>
              <p>Active Products</p>
            </div>
            {stockDetails && (
              <div className={`${classes.ProductTopComponent}`}>
                <h2>
                  {stockDetails[0]?.updatedAt
                    ? formatDateTime(stockDetails[0].updatedAt)
                    : ""}
                </h2>
                <p>Last Stock Updated Date</p>
              </div>
            )}
          </div>
        </div>

        <div className={`${classes.ProductDetailSearch}`}>
          <div className={`${classes.SearchProduct}`}>
            <input
              type="search"
              className={`${classes.ProductSearchBar} form-control`}
              aria-label="Search"
              placeholder="Search Product"
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
              value={searchQuery}
            />
            <span className={`${classes.SearchIcon}`}>
              <i className="bi bi-search"></i>
            </span>
            {/* {isDropdownOpen && (
              <ul className={classes.DropdownList}>
                {searchResults.map((item, index) => (
                  <li
                    key={item.PKID}
                    onClick={() => handleDropdownItemClick(item)}
                    className={
                      index === selectedIndex ? classes.SelectedItem : ""
                    }
                  >
                    {item.NameToDisplay}
                  </li>
                ))}
              </ul>
            )} */}
          </div>

          <DropdownInput
            value={statusFilter}
            name={"Status"}
            optionName={"Status"}
            setCategoryHandler={handleStatusFilter}
            categoryList={[
              { value: "", label: "All" },
              { value: "true", label: "Active Products 🟢" },
              { value: "false", label: "Inactive Products 🔴" },
            ]}
          />
          <SelectInput
            value={groupNameFilter}
            name={"Manufacture"}
            optionName={"Manufacturer"}
            setCategoryHandler={handleGroupNameFilter}
            categoryList={[...uniqueGroupNames, "Not Listed"]}
          />
        </div>

        <div className={`${classes.ProductDetailTable}`}>
          <table className={`${classes.TableComponent}`}>
            <thead className={`${classes.TableComponentHeader}`}>
              <tr>
                <th className={`${classes.TableName}`} scope="col">
                  PRODUCT NAME
                </th>
                <th className={`${classes.TableName}`} scope="col">
                  BRAND
                </th>
                <th className={`${classes.TableNum}`} scope="col">
                  CATEGORY
                </th>
                <th className={`${classes.TableNum}`} scope="col">
                  STATUS
                </th>

                <th className={`${classes.TableNum} text-center`} scope="col">
                  STOCK
                </th>
                <th className={`${classes.TableNum} text-end`} scope="col">
                  RATE
                </th>
                <th className={`${classes.TableNum} text-end`} scope="col">
                  MRP
                </th>

                <th className={`${classes.TableNum} text-end`} scope="col">
                  SCHEME
                </th>
                <th className={`${classes.TableName}`} scope="col">
                  MANUFACTURER
                </th>
                <th className={`${classes.TableNum}`} scope="col">
                  STRENGTH
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts.length > 0 ? (
                filteredProducts.map((product, index) => (
                  <tr
                    key={index}
                    onClick={() => setSelectedProduct(product)}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    className={`${
                      getStockForProduct(product.PKID) === 0
                        ? classes.outOfStock
                        : ""
                    }`}
                  >
                    <td className={`${classes.TableName}`}>
                      {product.ERPProductName}
                    </td>
                    <td className={`${classes.TableName}`}>
                      {product.ERPBrand}
                    </td>
                    <td className={`${classes.TableNum}`}>
                      {product.ERPCategory}
                    </td>
                    <td className={`${classes.TableNum}`}>
                      <p
                        className={`${styles.status} ${
                          product.active
                            ? `${styles.DeliveredOrder}`
                            : `${styles.CancelledOrder}`
                        }`}
                      >
                        {product.active ? "Active" : "Inactive"}
                      </p>
                    </td>

                    <td className={`${classes.TableNum} text-center`}>
                      {getStockForProduct(product.PKID)}
                    </td>
                    <td className={`${classes.TableNum} text-end`}>
                      {product.SaleRate.toFixed(2)}
                    </td>

                    <td className={`${classes.TableNum} text-end`}>
                      {product?.MRP?.toFixed(2)}
                    </td>

                    <td className={`${classes.TableNum}`}>{}</td>
                    <td className={`${classes.TableName}`}>
                      {product.MfgGroupName}
                    </td>
                    <td className={`${classes.TableNum}`}>
                      {product.Strength}
                    </td>
                  </tr>
                ))
              ) : loading ? (
                <div className={`${classes.PageWrapper}`}>
                  <Loader />
                </div>
              ) : (
                <tr>
                  <div className={`${classes.NoImageContainer}`}>
                    <img src={NoProducts} alt="no-products-found" />
                  </div>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <PaginationBox
          limit={limit}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        ref={productModalRef}
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                {selectedProduct?.NameToDisplay}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className={`${classes.ProductDetailModal} modal-body`}>
              <div className={`${classes.ProductImageContainer}`}>
                <img
                  src={
                    selectedProduct?.Images[0]
                      ? selectedProduct?.Images[0]
                      : noImg
                  }
                  alt="img"
                />
              </div>
              <p>
                <span>Brand Name</span>
                <span>:</span>
                <span>{selectedProduct?.ERPBrand}</span>
              </p>
              <p>
                <span>Category</span>
                <span>:</span>
                <span>{selectedProduct?.ERPCategory}</span>
              </p>
              <p>
                <span>HSN Code</span>
                <span>:</span>
                <span>{selectedProduct?.HSNCode}</span>
              </p>
              <p>
                <span>Group Name</span>
                <span>:</span>
                <span>{selectedProduct?.ERPGroupName}</span>
              </p>
              <p>
                {" "}
                <span>Strength</span>
                <span>:</span>
                <span>{selectedProduct?.ERPStrength}</span>{" "}
              </p>
              <p>
                <span>MRP</span>
                <span>:</span> <span>{selectedProduct?.MRP?.toFixed(2)}</span>{" "}
              </p>
              <p>
                <span>Rate</span>
                <span>:</span>{" "}
                <span>{selectedProduct?.SaleRate.toFixed(2)}</span>{" "}
              </p>
              <p>
                {" "}
                <span>MFG Group Name</span>
                <span>:</span>
                <span>{selectedProduct?.MfgGroupName}</span>{" "}
              </p>
            </div>
            <div class="modal-footer">
              {selectedProduct?.active && (
                <button
                  type="button"
                  className={`${styles.Btn} btn `}
                  onClick={openOrderlistPopup}
                >
                  Add to orderlist
                </button>
              )}
              <button
                type="button"
                className={`${styles.Btn} btn `}
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className="p-2">
          <Modal.Title className={styles.modalTitle}>Create Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <form>
              <div className="mb-3">
                <h5 className={styles.productName}>
                  {selectedProduct.NameToDisplay}
                </h5>
              </div>
              <div className={`${styles.QtyFieldBox} mb-3`}>
                <label className="form-label">Select Quantity</label>
                <div className={`${styles.QtyField}`}>
                  <input
                    ref={quantityInputRef}
                    type="text"
                    placeholder="Enter Qty..."
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  {/* <select className="form-select">
                    <option value="1">Piece</option>
                    <option value="2">Pieces</option>
                    <option value="1">Pack</option>
                    <option value="2">Packs</option>
                  </select> */}
                </div>
              </div>

              <div className={styles.orderAmountList}>
                <div className="mb-2 d-flex justify-content-between">
                  <h6>Rate</h6>
                  <h6>
                    <b>{selectedProduct?.SaleRate?.toFixed(2)}</b>
                  </h6>
                </div>
                <div className="mb-2 d-flex justify-content-between">
                  <h6>MRP</h6>
                  <h6>
                    <b>{selectedProduct?.MRP?.toFixed(2)}</b>
                  </h6>
                </div>

                {/* <div className=" mb-2 d-flex justify-content-between">
                  <h6>Qty.</h6>
                  <h6>
                    <b>{quantity}</b>
                  </h6>
                </div> */}
                <div className="d-flex flex-column mt-1">
                  <h6>Remark:</h6>
                  <textarea
                    className="p-2"
                    value={remark}
                    onChange={handleRemarkChange}
                  ></textarea>
                </div>
              </div>
              <div
                className={`${styles.TotalAmount} mb-3 d-flex justify-content-between`}
              >
                <h6>Total Amount</h6>
                <h6>
                  <b>{(quantity * selectedProduct.SaleRate).toFixed(2)}</b>
                </h6>
              </div>
              <div className="d-flex justify-content-end">
                <button
                  className={styles.Btn}
                  onClick={(e) => handleAddToOrderList(e)}
                >
                  Add
                </button>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductDetail;
