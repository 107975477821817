import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "products",
  initialState: {
    allProductList: [],
    allStockList:[]
  },
  reducers: {
    setProductListHandler(state, action) {
      state.allProductList = action.payload;
    },
    setStockListHandler(state, action) {
      state.allStockList = action.payload;
    },
  },
});

export const productActions = productSlice.actions;
export default productSlice;
