import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format, parse } from "date-fns";
import styles from "./Outstanding.module.css";
import classes from "../productDetail/ProductDetail.module.css";
// import NoContent from "../../Assessts/Images/NoContent.png";
import Loader from "../../reusableComponents/Loader/Loader";
import Header from "../../reusableComponents/Header";
import CustomDatePicker from "../../reusableComponents/CustomDatePicker/CustomDatePicker";
import { getOutstanding } from "../../Store/Customer/CustomerActions";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { path } from "../../Router/Routes.js";


const Outstanding = () => {
  const { id , locationid} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [outstanding, setOutstanding] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toDate, setToDate] = useState(format(new Date(), "dd/MM/yyyy"));
  const [autoAdjust, setAutoAdjust] = useState(true);

  useEffect(() => {
    fetchOutstanding();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 

  useEffect(() => {
    if(!B2BauthToken)
      {
        toast.error("Please login first!")
        navigate(path.login);
      }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));  

  }, [B2BauthToken, dispatch, id, locationid, navigate]);


  const fetchOutstanding = () => {
    if (B2BauthToken) {
      if (toDate) {
        setLoading(true);
        const apiFormattedDate = format(
          parse(toDate, "dd/MM/yyyy", new Date()),
          "yyyy/MM/dd"
        );
        dispatch(
          getOutstanding(B2BauthToken, id, apiFormattedDate, autoAdjust, locationid)
        ).then((result) => {
          if (result.status === "success") {
            setLoading(false);
            setOutstanding(result.data.data);
          } else {
            setLoading(false);
          }
        });
      } else {
        toast.error("Please select a date first!");
      }
    } else {
      toast.error("Please login first!");
    }
  };

  return (
    <>
      <Header pagename={"Outstanding"} />
      <div className={styles.datePickerContainer}>
        <div className="d-flex gap-4 align-items-center">
          <div>
            <label className={`${styles.dateText}  `} htmlFor="fromDate">
              As On:
            </label>
            <CustomDatePicker selectedDate={toDate} onChange={setToDate} />
          </div>
          <div onClick={()=>setAutoAdjust(!autoAdjust)}>
            <input
              type="checkbox"
              checked={autoAdjust}
              onChange={() => setAutoAdjust(!autoAdjust)}
            />
            <label className={`${styles.dateText}  `} htmlFor="toDate">
              Auto Adjust{" "}
            </label>
          </div>
        </div>

        <div className={`${styles.SubmitBtn}`}>
          <button className={`${styles.Btn}`} onClick={fetchOutstanding}>
            View
          </button>
        </div>
      </div>
      {loading === true ? (
        <div className={`${styles.PageWrapper}`}>
          <Loader />
        </div>
      ) : (
        <>
          {outstanding && outstanding.length ? (
            <div className={`${styles.OutstandingDetailTable}`}>
              <table className={`${classes.TableComponent}`}>
                <thead className={`${classes.TableComponentHeader}`}>
                  <tr>
                    <th className={`${classes.TableName}`} scope="col">
                      TRANSACTION NAME
                    </th>
                    <th className={`${classes.TableNum} `} scope="col">
                      ENTRY NO.
                    </th>
                    <th
                      className={`${classes.TableNum} text-center`}
                      scope="col"
                    >
                      ENTRY DATE
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      NET AMOUNT
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      DUE AMOUNT
                    </th>
                    <th
                      className={`${classes.TableNum} text-center`}
                      scope="col"
                    >
                      DUE DATE
                    </th>
                    <th className={`${classes.TableNum} text-end`} scope="col">
                      PDC AMOUNT
                    </th>

                    <th
                      className={`${classes.TableNum} text-center`}
                      scope="col"
                    >
                      NO. OF DAYS
                    </th>
                    <th
                      className={`${classes.TableNum} text-center`}
                      scope="col"
                    >
                      OVERDUE DAYS
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {outstanding.slice(0,-1).map((product, index) => (
                    <tr key={index}>
                      <td className={`${classes.TableName} `}>
                        {product.TranName}
                      </td>
                      <td className={`${classes.TableNum} `}>
                        {product.EntryNo}
                      </td>
                      <td className={`${classes.TableNum} text-center`}>
                        {new Date(product.EntryDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product.NetAmt !== 0  && product.NetAmt !== null? `${product.NetAmt.toFixed(2)}` : ""}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product.DueAmt !== 0 && product.DueAmt !== null ? `${product.DueAmt.toFixed(2)}` : ""}
                      </td>
                      <td className={`${classes.TableNum} text-center`}>
                        {new Date(product.DueDate)
                          .toLocaleDateString("en-GB")
                          .replace(/\//g, "-")}
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        {product.PDCAmt !== 0 && product.PDCAmt !== null ? `${product.PDCAmt.toFixed(2)}` : ""}
                      </td>
                      <td className={`${classes.TableNum} text-center`}>
                        {product.NoOfDays}
                      </td>
                      <td className={`${classes.TableNum} text-center`}>
                        {product.OverDueDays}
                      </td>
                    </tr>
                  ))}

                  {outstanding.slice(-1).map((product, index) => (
                    <tr key={index + 1000}>
                      <td className={`${classes.TableName}`}>
                        <strong>Total</strong>
                      </td>
                      <td className={`${classes.TableNum}`}>{}</td>
                      <td className={`${classes.TableNum}`}>{}</td>
                      <td className={`${classes.TableNum} text-end`}>
                        <strong>
                          {" "}
                          {product.NetAmt !== 0  && product.NetAmt !== null ? `${product.NetAmt.toFixed(2)}` : ""}
                        </strong>
                      </td>
                      <td className={`${classes.TableNum} text-end`}>
                        <strong>
                          {" "}
                          {product.DueAmt !== 0 && product.DueAmt !== null ? `${product.DueAmt.toFixed(2)}` : ""}
                        </strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={`${classes.NoImageContainer}`}>
              {/* <img src={NoContent} alt="no-data-found" /> */}
              <h6>Data not found.</h6>

            </div>
          )}
        </>
      )}
    </>
  );
};

export default Outstanding;
