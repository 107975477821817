import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./CustomDropdown.module.css";

const CustomDropdown = ({ label, options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (itemValue) => {
    onChange(itemValue);
    toggleDropdown();
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className={classes.customdropdown} ref={dropdownRef}>
      <div className={classes.dropdownheader} onClick={toggleDropdown}>
        {value ? (
          <div className={classes.selecteditem}>
            {options.find((option) => option.value === value)?.label}
          </div>
        ) : (
          <div className={classes.placeholder}>{label}</div>
        )}
        <i
          className={`${classes.dropdownicon} bi bi-chevron-${
            isOpen ? "up" : "down"
          }`}
        ></i>
      </div>
      {isOpen && (
        <div className={classes.dropdownlist}>
          {options.map((option) => (
            <div
              key={option.value}
              className={`${classes.dropdownitem} ${
                value === option.value ? "selected" : ""
              }`}
              onClick={() => handleItemClick(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CustomDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CustomDropdown;
