import { createSlice } from "@reduxjs/toolkit";

const storeSlice = createSlice({
  name: "store",

  initialState: {
    b2bStores: [],
    storeById: {},
    locationById:{},
    storeId: null,
    locationId:null,
    b2bLoc: [],
  },

  reducers: {
    setStoreListDetailHandler(state, action) {
      state.b2bStores = action.payload;
    },
    setStoreHandler(state, action) {
      state.storeById = action.payload;
    },
    setLocationHandler(state, action){
      state.locationById = action.payload;
    },
    setStoreIdHandler(state, action) {
      state.storeId = action.payload;
    },
    setLocationIdHandler(state, action) {
      state.locationId = action.payload;
    },
    setStoreLocDetailHandler(state, action) {
      state.b2bLoc = action.payload;
    },
  },
});

export const storeActions = storeSlice.actions;

export default storeSlice;
