import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import swilmart from "../../Assessts/Images/swilmart.png";
import classes from "./Login.module.css";
import { SignUpHandler } from "../../Store/Auth/AuthActions";

const Signup = ({ storeId, onClose, onSignupSuccess }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const LogData = useSelector((state) => state.auth.LogData);
  const [step1FormData, setStep1FormData] = useState({
    CompanyName: "",
    ContactNumber: LogData.ContactNumber,
    emailId: "",
    AddressLine1: "",
    Pincode: "",
    Locality: "",
    city: "",
    State: "",
  });
  const [step1Errors, setStep1Errors] = useState({
    CompanyName: false,
    emailId: false,
    AddressLine1: false,
    city: false,
    State: false,
    Locality: false,
    Pincode: false,
  });
  const [step2FormData, setStep2FormData] = useState({
    License: "",
    GST_Number: "",
    PAN_Number: "",
  });
 
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateStep1 = () => {
    const {
      CompanyName,
      emailId,
      AddressLine1,
      city,
      State,
      Locality,
      Pincode,
    } = step1FormData;
    const newErrors = {
      CompanyName: CompanyName === "",
      emailId: emailId === "" || !validateEmail(emailId),
      AddressLine1: AddressLine1 === "",
      city: city === "",
      State: State === "",
      Locality: Locality === "",
      Pincode: Pincode === "",
    };
    setStep1Errors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const validateStep2 = () => {
   
    return true;
  };

  const nextStep = () => {
    if (step === 1) {
      const isValidStep1 = validateStep1();
      if (isValidStep1) {
        setStep(2);
      } else {
        toast.error("Please fill in all fields before proceeding.");
      }
    } else if (step === 2) {
      const isValidStep2 = validateStep2();
      if (isValidStep2) {
        setStep(3);
      } else {
        toast.error("Please fill in all fields before proceeding.");
      }
    }
  };

  // const prevStep = () => {
  //   setStep(step - 1);
  // };

  const goToStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (step === 1) {
      setStep1FormData({
        ...step1FormData,
        [name]: value,
      });
    } else if (step === 2) {
      setStep2FormData({
        ...step2FormData,
        [name]: value,
      });
    }
  };
  const saveHandler = (e) => {
    e.preventDefault();
    if (step === 1) {
      const isValidStep1 = validateStep1();
      if (isValidStep1) {
        setStep(2);
      } else {
        toast.error("Please fill in all fields before proceeding.");
      }
    } else if (step === 2) {
      const isValidStep2 = validateStep2();
      if (isValidStep2) {
        if (B2BauthToken) {
          const formData = { ...step1FormData, ...step2FormData };
          dispatch(SignUpHandler(B2BauthToken, storeId, formData)).then(
            (result) => {
              if (result.status === "success") {
                onClose();
                onSignupSuccess(); 
              } else {
                toast.error("An error occurred. Please try again later.");
                onClose();
              }
            }
          );
        }
      } else {
        toast.error("Please fill in all fields before submitting.");
      }
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className={`${classes.SignupStepForm}`}>
            <div className={`${classes.SinupField}`}>
              <label className={`${classes.SignUpLabel}`}>Store Name</label>
              <input
                type="text"
                placeholder="Your Company Name"
                className={`form-control ${classes.SignUpInput} ${
                  step1Errors.CompanyName ? "is-invalid" : ""
                }`}
                value={step1FormData.CompanyName}
                onChange={handleChange}
                name="CompanyName"
                required
              />
              {step1Errors.CompanyName && (
                <div className={`${classes.invalidErr} invalid-feedback`} >
                  Please provide a company name.
                </div>
              )}
            </div>

            <div className="d-flex w-100">
              <div className={`${classes.SinupField}`}>
                <label className={`${classes.SignUpLabel}`}>Mobile</label>
                <input
                  type="text"
                  placeholder="Your Contact Number"
                  className={classes.SignUpInput}
                  value={LogData.ContactNumber}
                  onChange={handleChange}
                  name="ContactNumber"
                  disabled={true}
                />
              </div>

              <div className={`${classes.SinupField}`}>
                <label className={`${classes.SignUpLabel}`}>Email</label>
                <input
                  type="text"
                  placeholder="Your Email"
                  className={`form-control ${classes.SignUpInput} ${
                    step1Errors.emailId ? "is-invalid" : ""
                  }`}
                  value={step1FormData.emailId}
                  onChange={handleChange}
                  name="emailId"
                />
                {step1Errors.emailId && (
                  <div className={`${classes.invalidErr} invalid-feedback`}>
                    Please provide a valid email.
                  </div>
                )}
              </div>
            </div>
            <div className={`${classes.SinupField}`}>
              <label className={`${classes.SignUpLabel}`}>Address</label>
              <input
                type="text"
                placeholder="Your Address"
                className={`form-control ${classes.SignUpInput} ${
                  step1Errors.AddressLine1 ? "is-invalid" : ""
                }`}
                value={step1FormData.AddressLine1}
                onChange={handleChange}
                name="AddressLine1"
              />
              {step1Errors.AddressLine1 && (
                <div className={`${classes.invalidErr} invalid-feedback`}>
                  Please provide an address.
                </div>
              )}
            </div>
            <div className="d-flex w-100">
              <div className={`${classes.SinupField}`}>
                <label className={`${classes.SignUpLabel}`}>City</label>
                <input
                  type="text"
                  placeholder="Your City"
                  className={`form-control ${classes.SignUpInput} ${
                    step1Errors.city ? "is-invalid" : ""
                  }`}
                  value={step1FormData.city}
                  onChange={handleChange}
                  name="city"
                />
                {step1Errors.city && (
                  <div className={`${classes.invalidErr} invalid-feedback`}>
                    Please provide a city name.
                  </div>
                )}
              </div>

              <div className={`${classes.SinupField}`}>
                <label className={`${classes.SignUpLabel}`}>State</label>
                <input
                  type="text"
                  placeholder="Your State"
                  className={`form-control ${classes.SignUpInput} ${
                    step1Errors.State ? "is-invalid" : ""
                  }`}
                  value={step1FormData.State}
                  onChange={handleChange}
                  name="State"
                />
                {step1Errors.State && (
                  <div className={`${classes.invalidErr} invalid-feedback`}>
                    Please provide a state.
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex w-100">
              <div className={`${classes.SinupField}`}>
                <label className={`${classes.SignUpLabel}`}>Locality</label>
                <input
                  type="text"
                  placeholder="Your Locality"
                  className={`form-control ${classes.SignUpInput} ${
                    step1Errors.Locality ? "is-invalid" : ""
                  }`}
                  value={step1FormData.Locality}
                  onChange={handleChange}
                  name="Locality"
                />
                {step1Errors.Locality && (
                  <div className={`${classes.invalidErr} invalid-feedback`}>
                    Please provide a locality.
                  </div>
                )}
              </div>
              <div className={`${classes.SinupField}`}>
                <label className={`${classes.SignUpLabel}`}>Pincode</label>
                <input
                  type="text"
                  maxLength="6"
                  placeholder="Your Pincode"
                  className={`form-control ${classes.SignUpInput} ${
                    step1Errors.Pincode ? "is-invalid" : ""
                  }`}
                  value={step1FormData.Pincode}
                  onInput={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (!regex.test(e.target.value)) {
                      e.target.value = "";
                    }
                  }}
                  onChange={handleChange}
                  name="Pincode"
                />
                {step1Errors.Pincode && (
                  <div className={`${classes.invalidErr} invalid-feedback`}>
                    Please provide a pincode.
                  </div>
                )}
              </div>
            </div>
            <button
              type="button"
              onClick={nextStep}
              className={classes.SubmitButton}
            >
              Next
            </button>
          </div>
        );
      case 2:
        return (
          <div className={`${classes.SignupStepForm} `} novalidate>
            <div className={`${classes.SinupField}`}>
              <label className={`${classes.SignUpLabel}`}>
                Drug License No.
              </label>
              <input
                type="text"
                placeholder="License Number"
                className={`form-control ${classes.SignUpInput} `}
                value={step2FormData.License}
                maxLength="6"
                onChange={handleChange}
                name="License"
              />
             
            </div>

            <div className={`${classes.SinupField}`}>
              <label className={`${classes.SignUpLabel}`}>GSTIN</label>
              <input
                type="text"
                placeholder="GSTIN"
                className={`form-control ${classes.SignUpInput} `}
                value={step2FormData.GST_Number}
                maxLength="15"
                onChange={handleChange}
                name="GST_Number"
              />
             
            </div>
            <div className={`${classes.SinupField}`}>
              <label className={`${classes.SignUpLabel}`}>PAN Number</label>
              <input
                type="text"
                placeholder="PAN Number"
                className={`form-control ${classes.SignUpInput}`}
                value={step2FormData.PAN_Number}
                maxLength="10"
                onChange={handleChange}
                name="PAN_Number"
              />
         
            </div>

            <button
              type="submit"
              className={classes.SubmitButton}
              onClick={saveHandler}
            >
              Submit
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const getHeading = () => {
    switch (step) {
      case 1:
        return "Enter Store Details";
      case 2:
        return "Enter Registration Details";
      default:
        return "Signup";
    }
  };

  return (
    <div className={`${classes.SignUpOverlay}`}>
      <div className={`${classes.SignUpWrapper}`}>
        <div className={`${classes.SignupHeading}`}>
          <img src={swilmart} alt="logo" />
          <h1>{getHeading()}</h1>
          <span className={classes.CloseButton} onClick={onClose}>
            <i className="bi bi-x-lg"></i>
          </span>
        </div>
        <div className={classes.StepSwitcher}>
          <p
            onClick={() => goToStep(1)}
            className={step >= 1 ? classes.ActiveStep : classes.Step}
          >
            <span>1</span> Store Details
          </p>
          <p>
            <i className="bi bi-chevron-right"></i>
          </p>
          <p
            onClick={() => goToStep(2)}
            className={step >= 2 ? classes.ActiveStep : classes.Step}
          >
            <span>2</span> Registration Details
          </p>
        </div>
        <div className={`${classes.signUpForm}`}>
          <form onSubmit={saveHandler} className="needs-validation" novalidate>
            {renderStepContent()}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
