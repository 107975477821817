import React from "react";
import classes from "./Footer.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const storeId = useSelector((state) => state.store.storeId);
  const locationId = useSelector((state) => state.store.locationId);
  const navigate = useNavigate();

  return (
    <div className={`${classes.FooterWrapper}`}>
      <div className={`${classes.FooterLinks}`}>
        <li
          onClick={() =>
            navigate(`/${storeId}/${locationId}/companyDetails/privacyPolicy`, {
              state: { id: storeId, name: "Privacy Policy" },
            })
          }
        >
          <h6> Privacy Policy</h6>
        </li>
        <li
          onClick={() =>
            navigate(`/${storeId}/${locationId}/companyDetails/returnPolicy`, {
              state: { id: storeId, name: "Return Policy" },
            })
          }
        >
          <h6>Return Policy</h6>
        </li>
        <li
          onClick={() =>
            navigate(`/${storeId}/${locationId}/companyDetails/shippingPolicy`, {
              state: { id: storeId, name: "Shipping Policy" },
            })
          }
        >
          <h6> Shipping Policy</h6>
        </li>
        {/* <li
          onClick={() =>
            navigate(`/${storeId}/${locationId}/companyDetails`, {
              state: { id: storeId, name: "Contact Us" },
            })
          }
        >
          <h6> Contact Us</h6>
        </li> */}
      </div>
      <div className={`${classes.FooterCopyright}`}>
        <p>2024-25 Swilmart.com. All rights reseved</p>
      </div>
    </div>
  );
};

export default Footer;
