import React, { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import classes from "./Header.module.css";

const TooltipComp = ({ children, tooltipText, placement = 'right' }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
      {children}
      <Overlay target={target.current} show={show} placement={placement}>
        {(props) => (
          <Tooltip id="overlay-example" {...props} className={`${classes.customtooltip}`}   >
            {tooltipText}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default TooltipComp;
