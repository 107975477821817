import { createSlice } from "@reduxjs/toolkit";

const orderlistSlice = createSlice({
  name: "orderList",
  initialState: {
    orderList: [],
    totalPrice: 0,
    orderData: [],
    orderPrice: 0,
    profileData:{},
  },
  reducers: {
    calculateTotalPriceHandler(state, action) {
      state.totalPrice = action.payload;
    },

    setOrderList(state, action) {
      state.cartList = action.payload;
    },

    setProfileData(state, action) {
      state.profileData = action.payload;
    },

}
});

export const orderListActions = orderlistSlice.actions;

export default orderlistSlice;
