import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./Auth/AuthSlice";
import storeSlice from "./StoreDetails/StoreSlice";
import orderlistSlice from "./OrderList/OrderListSlice";
import productSlice from "./Product/ProductSlice";

const store = configureStore({
    reducer: {
    auth:authSlice.reducer,
    store:storeSlice.reducer,
    orderList:orderlistSlice.reducer,
    products: productSlice.reducer,
    }
  })
  
  export default store