import React, { useEffect } from "react";
import classes from "./coming.module.css";
import { useParams } from "react-router-dom";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { useDispatch } from "react-redux";

const Comingsoon = () => {
  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
  }, [dispatch,id, locationid]);

  return (
    <div className={classes.wrapper}>
      <h1>Coming soon</h1>
      <h6>This page is under construction</h6>
    </div>
  );
};

export default Comingsoon;
