import React, { useEffect, useState } from "react";
import classes from "./Header.module.css";
import swilmart from "../../Assessts/Images/swilmart.png";
import { useDispatch, useSelector } from "react-redux";
import {
  LogOutHandle,
  LoggedInHandler,
  getProfileHandler,
} from "../../Store/Auth/AuthActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { path } from "../../Router/Routes.js";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import {
  getLocationByIdHandle,
  getStoreByIdHandle,
} from "../../Store/StoreDetails/StoreActions";
import { orderListActions } from "../../Store/OrderList/OrderListSlice.js";

const MainHeader = () => {
  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const profileData = useSelector((state) => state.orderList.profileData);
  const locationId = useSelector((state) => state.store.locationId);
  const LogData = useSelector((state) => state.auth.LogData);
  const [loggedin, setLoggedin] = useState(false);
  const [storedata, setStoredata] = useState(null);
  const [locationDetails, setLocationDetails] = useState(null);

  const LogOut = () => {
    if (B2BauthToken) {
      dispatch(LogOutHandle(B2BauthToken)).then((result) => {
        if (result.status === "success") {
          navigate(path.login);
          //yes checking live
          toast.success("Logged out successfully!");
        }
      });
    }
  };

  const backtoStore = () => {
    navigate(path.storeList);
  };

  useEffect(() => {
    dispatch(storeActions.setStoreIdHandler(id));

    if (B2BauthToken) {
      dispatch(LoggedInHandler(B2BauthToken)).then((result) => {
        if (result.status === "success") {
          setLoggedin(true);
          dispatch(getProfileHandler(B2BauthToken, id)).then((result) => {
            if (result.status === "success") {
              if (result.data.registered === true) {
                dispatch(orderListActions.setProfileData(result.data.data[0]));
              }
            }
          });
          if (B2BauthToken && id) {
            dispatch(getStoreByIdHandle(B2BauthToken, id)).then((result) => {
              if (result.status === "success") {
                setStoredata(result.data);
              }
            });
          }
          if (B2BauthToken && id && locationid) {
            dispatch(getLocationByIdHandle(id, locationid)).then((result) => {
              if (result.status === "success") {
                setLocationDetails(result.data);
              }
            });
          }
        } else {
          navigate(path.login);
        }
      });
    }
  }, [B2BauthToken, navigate, dispatch, id, locationid]);

  return (
    <div className={`${classes.MainHeaderWrapper}`}>
      <div className={`${classes.MainHeaderLogo}`} onClick={backtoStore}>
        <img
          src={storedata?.Logo ? storedata?.Logo : swilmart}
          alt="swilmart-logo"
        />
      </div>
      <div className={`${classes.searchContainer} `}>
        {/* <input
          type="search"
          disabled
          className={`${classes.inputSearch} form-control `}
          aria-label="Search"
          placeholder="What are you looking for...."
        />
        <button className={`${classes.searchButton}`}>
          <span>
            <i className="bi bi-search"></i>
            <p>Search</p>
          </span>
        </button> */}
        <p>
          {locationDetails?.StoreName && locationDetails?.LocationName
            ? locationDetails?.StoreName + " - " + locationDetails?.LocationName
            : ""}
        </p>
      </div>

      <div className={`${classes.ProfileInfo}`}>
        <div
          className={`${classes.ProfileButton}`}
          {...(loggedin
            ? { "data-bs-toggle": "dropdown", "aria-expanded": "false" }
            : {
                onClick: () => {
                  navigate(path.login);
                },
              })}
        >
          <span className={`${classes.avatarBox}`}>
            <i className="bi bi-person"></i>
          </span>
          <div className="dropdown">
            <ul className={`${classes.profileDropdown} dropdown-menu`}>
              {location.pathname === "/store-list" ? (
                <div className={`${classes.profileHeader}`}>
                  <div className={`${classes.profileName}`}>
                    <h5>Hello Guest</h5>

                    {LogData?.ContactNumber && (
                      <p>
                        <span>{LogData?.ContactNumber}</span>
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                profileData && (
                  <>
                    <div className={`${classes.profileHeader}`}>
                      {location.pathname !== "/store-list" ? (
                        <span
                          className={`${classes.EditIcon}`}
                          onClick={() =>
                            navigate(
                              path.profileCustomer
                                .replace(":id", id)
                                .replace(":locationid", locationId)
                            )
                          }
                        >
                          <i class="bi bi-pencil"></i>
                        </span>
                      ) : (
                        ""
                      )}

                      <div className={`${classes.profileName}`}>
                        <h5>
                          {profileData?.CompanyName
                            ? profileData?.CompanyName
                            : "Guest"}
                        </h5>
                        <p>
                          <span>{profileData?.ContactNumber}</span>
                        </p>
                        <p>
                          <span>{profileData?.Email}</span>
                        </p>
                      </div>
                    </div>
                  </>
                )
              )}

              {storedata && (
                <>
                  <div className={`${classes.profileHeaderStore}`}>
                    <div className={`${classes.profileop}`}>
                      <h5>Store Details</h5>
                    </div>
                    {storedata?.Logo && (
                      <div className={`${classes.profileLogo}`}>
                        <img src={storedata?.Logo} alt="logo" />
                      </div>
                    )}
                    <div className={`${classes.profileName}`}>
                      <h5>{storedata?.StoreName}</h5>
                      <p>
                        <span>{storedata?.Email}</span>
                      </p>

                      <p>
                        <span>{storedata?.address}</span>
                      </p>
                      <p>
                        <span>{storedata?.MobileNumber}</span>
                      </p>
                    </div>
                    <div className={`${classes.socialLInks}`}>
                      {storedata.FacebookURL && (
                        <span
                          onClick={() =>
                            window.open(storedata.FacebookURL, "_blank")
                          }
                        >
                          <i className="bi bi-facebook"></i>
                        </span>
                      )}
                      {storedata.TwitterURL && (
                        <span
                          onClick={() =>
                            window.open(storedata.TwitterURL, "_blank")
                          }
                        >
                          <i className="bi bi-twitter"></i>
                        </span>
                      )}
                      {storedata.InstagramURL && (
                        <span
                          onClick={() =>
                            window.open(storedata.InstagramURL, "_blank")
                          }
                        >
                          <i className="bi bi-instagram"></i>
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}

              <li className={`${classes.profilelogOut}`} onClick={LogOut}>
                <span>
                  <i class="bi bi-box-arrow-left"></i>
                </span>
                <span>Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
