import React from "react";
import MainHeader from "../header/MainHeader";
import SideHeader from "../header/SideHeader";
import Footer from "../../reusableComponents/Footer/Footer";

const Layout = (props) => {
  return (
    <div className="mainB2b">
      <MainHeader />
      <SideHeader>{props.children}</SideHeader>
      <Footer/>
    </div>
  );
};

export default Layout;
