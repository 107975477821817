export const DateFomatyyyymmdd = (date) => {
    if (!date) return "";
    const [day, month, year] = date.split("/");
    return `${year}/${month}/${day}`;
  };
  

  export const DateFormatDDmmYYYY = (date)=>{
    const dateParts = date.split("/");
    return `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`;
  }


  export const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };