import axios from "axios";


export const NodeInstance = axios.create({
  baseURL: window.baseUrl,
})

export const StoreInstance = axios.create({
  baseURL: process.env.REACT_APP_STOREMICROPATH,
});

export const CustomerInstance = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMERMICROPATH,
});

export const ProductInstance = axios.create({
  baseURL: process.env.REACT_APP_PRODUCTMICROPATH,
});

export const OrderInstance = axios.create({
  baseURL: process.env.REACT_APP_ORDERMICROPATH,
});


