import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./Ledger.module.css";
import classes from "../productDetail/ProductDetail.module.css";
import CustomDatePicker from "../../reusableComponents/CustomDatePicker/CustomDatePicker";
import Header from "../../reusableComponents/Header";
import Loader from "../../reusableComponents/Loader/Loader";
import { storeActions } from "../../Store/StoreDetails/StoreSlice";
import { getCustomerLedger } from "../../Store/Customer/CustomerActions";
import { path } from "../../Router/Routes.js";
import { format } from "date-fns";

const Ledger = () => {
  const { id, locationid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const B2BauthToken = localStorage.getItem("B2BAuthToken");
  const [ledger, setLedger] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(format(new Date(), "dd/MM/yyyy"));
  const [toDate, setToDate] = useState(format(new Date(), "dd/MM/yyyy"));

  useEffect(() => {
    handleDateSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!B2BauthToken) {
      toast.error("Please login first!");
      navigate(path.login);
    }
    dispatch(storeActions.setStoreIdHandler(id));
    dispatch(storeActions.setLocationIdHandler(locationid));
  }, [B2BauthToken, dispatch, id, locationid, navigate]);

  const isFromDateGreaterThanToDate = (from, to) => {
    const fromDate = new Date(from.split("/").reverse().join("-"));
    const toDate = new Date(to.split("/").reverse().join("-"));
    return fromDate > toDate;
  };

  const handleDateSubmit = () => {
    if (B2BauthToken) {
      if (fromDate != null && toDate != null) {
        if (isFromDateGreaterThanToDate(fromDate, toDate)) {
          toast.error("From date cannot be greater than to date");
          return;
        }
        setLoading(true);
        dispatch(
          getCustomerLedger(B2BauthToken, id, locationid, fromDate, toDate)
        ).then((result) => {
          if (result.status === "success") {
            setLedger(result.data.data.Result);
          }
          setLoading(false);
        });
      } else {
        toast.error("Please select the dates");
      }
    } else {
      toast.error("Please Login First!");
    }
  };

  return (
    <>
      <Header pagename={"Ledger"} />

      <div className={styles.LedgerDetailWrapper}>
        <div className={styles.datePickerContainer}>
          <div className="d-flex gap-4 align-center">
            <div>
              <label className={`${styles.dateText}  `} htmlFor="fromDate">
                From Date:
              </label>

              <CustomDatePicker
                selectedDate={fromDate}
                onChange={(date) => {
                  if (isFromDateGreaterThanToDate(date, toDate)) {
                    toast.error("From date cannot be greater than to date");
                    return;
                  }
                  setFromDate(date);
                }}
              />
            </div>

            <div>
              <label className={`${styles.dateText}  `} htmlFor="toDate">
                To Date:
              </label>

              <CustomDatePicker
                selectedDate={toDate}
                onChange={(date) => {
                  if (isFromDateGreaterThanToDate(fromDate, date)) {
                    toast.error("To date cannot be less than from date");
                    return;
                  }
                  setToDate(date);
                }}
              />
            </div>
          </div>
          <div className={`${styles.SubmitBtn}`}>
            <button className={`${styles.Btn}`} onClick={handleDateSubmit}>
              View
            </button>
          </div>
        </div>

        {loading === true ? (
          <div className={`${styles.PageWrapper}`}>
            <Loader />
          </div>
        ) : (
          <>
            {ledger && ledger.length ? (
              <div className={`${styles.LedgerDetailTable}`}>
                <table
                  className={`${classes.TableComponent} ${styles.LedgerTable}`}
                >
                  <thead className={`${classes.TableComponentHeader}`}>
                    <tr>
                      <th className={`${classes.TableName} `} scope="col">
                        NARRATION
                      </th>
                      <th
                        className={`${classes.TableNum} text-end`}
                        scope="col"
                      >
                        CREDIT
                      </th>
                      <th
                        className={`${classes.TableNum} text-end`}
                        scope="col"
                      >
                        DEBIT
                      </th>
                      <th
                        className={`${classes.TableNum} text-end`}
                        scope="col"
                      >
                        BALANCE
                      </th>

                      <th
                        className={`${classes.TableName} text-center`}
                        scope="col"
                      >
                        REF NO.
                      </th>
                      <th
                        className={`${classes.TableName} text-center`}
                        scope="col"
                      >
                        REF DATE
                      </th>
                      <th
                        className={`${classes.TableNum} text-center`}
                        scope="col"
                      >
                        CHEQUE NO.
                      </th>
                      <th
                        className={`${classes.TableName} text-center`}
                        scope="col"
                      >
                        CHEQUE DATE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ledger.slice(1, -1).map((product, index) => (
                      <tr key={index}>
                        <td className={`${styles.FirstRow}`}>
                          {product.VoucherNarration}
                        </td>
                        <td className={`${classes.TableNum} text-end`}>
                          {product.Credit !== 0
                            ? `${product.Credit.toFixed(2)}`
                            : ""}
                        </td>

                        <td className={`${classes.TableNum} text-end`}>
                          {product.Debit !== 0
                            ? `${product.Debit.toFixed(2)}`
                            : ""}
                        </td>
                        <td className={`${classes.TableNum} text-end`}>
                          {product.Bal !== 0 ? `${product.Bal.toFixed(2)}` : ""}
                        </td>

                        <td className={`${classes.TableName} text-center`}>
                          {product.RefNo}
                        </td>
                        <td className={`${classes.TableName} text-center`}>
                          {" "}
                          {new Date(product.RefDate)
                            .toLocaleDateString("en-GB")
                            .replace(/\//g, "-")}
                        </td>
                        <td className={`${classes.TableNum} text-center`}>
                          {product.ChequeNo}
                        </td>
                        <td className={`${classes.TableName} text-center`}>
                          {" "}
                          {new Date(product.ChequeDate).getTime() ===
                          new Date("1970-01-01").getTime()
                            ? ""
                            : new Date(product.ChequeDate)
                                .toLocaleDateString("en-GB")
                                .replace(/\//g, "-")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {ledger.slice(-1).map((product) => (
                  <div className={`${styles.TotalWrapper}`}>
                    <p className={`${styles.TotalHeading}`}>
                      <strong>Total:</strong>
                    </p>
                    <div className={`${styles.TotalAmountDiv}`}>
                      <p>
                        <strong>
                          {product.Credit && product.Credit !== 0
                            ? `${product.Credit.toFixed(2)}`
                            : ""}
                        </strong>
                      </p>

                      <p>
                        <strong>
                          {product.Debit && product.Debit !== 0
                            ? `${product.Debit.toFixed(2)}`
                            : ""}
                        </strong>
                      </p>
                      <p>
                        <strong>
                          {product.Bal && product.Bal !== 0
                            ? `${product.Bal.toFixed(2)}`
                            : ""}
                        </strong>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={`${styles.NoImageContainer}`}>
                <h6>Data not found.</h6>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Ledger;
