import React from "react";
import classes from "./PaginationBox.module.css";
// import ReactPaginate from "react-paginate";
import { ReactComponent as ArrowRightSvg } from "../../Assessts/SvgComponents/arrowRightSvg.svg";
import { ReactComponent as ArrowLeft } from "../../Assessts/SvgComponents/ArrowLeft.svg";


function PaginationBox({
  limit,
  totalItems,
  currentPage,
  totalPages,
  onPageChange,
 }) {


    const handlePageChange = (page) => {
      onPageChange(page);
    };
  
    
  return (
    <div className={classes.paginationContent}>
      <div className={classes.paginationInfo}>
        Showing {currentPage} to {limit} of {totalItems} results.
      </div>
      <div className={classes.paginationControls}>
        <button
           className={`${classes.prevButton} ${currentPage === 1 ? classes.opacSvg : ""}`}
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
        >
           <ArrowLeft />
        </button>
        <span className={classes.currentPage}>{currentPage}</span>
        {/* <div className={classes.reactPaginate}>
        <ReactPaginate
          previousLabel={<ArrowLeft />}
          nextLabel={<ArrowRightSvg />}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          Displayed Page Range = {5}  
          onPageChange={handlePageChange}
          containerClassName={classes.pagination}
          activeClassName={classes.active}
          pageClassName={classes.pageItem}
          previousClassName={classes.prevButton}
          nextClassName={classes.nextButton}
        />
      </div> */}
        <button
           className={`${classes.nextButton} ${currentPage === totalPages ? classes.opacSvg : ""}`}
          onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
        >
         <ArrowRightSvg/>
        </button>
      </div>
     
    </div>
  );
}

export default PaginationBox;