import React from "react";
import classes from "./SideDrawer.module.css";
import { useSelector } from "react-redux";
import noImg from "../../Assessts/Images/noImg.png";

const SideDrawer = ({ isOpen, onClose, order, orderFlag }) => {
 
  const productList = useSelector((state) => state.products.allProductList);
  const shippingAddressArray =
    !orderFlag && order?.ShippingAddressRemark
      ? JSON.parse(order?.ShippingAddressRemark)
      : [];

  return (
    <div className={`${classes.drawer} ${isOpen ? classes.open : ""}`}>
      <div className={classes.drawerContent}>
        <button onClick={onClose} className={classes.closeButton}>
          <i className="bi bi-x"></i>
        </button>
        <div className={classes.drawerBody}>
          <h2 className={`${classes.Heading}`}>Order Details</h2>
          <div className={classes.CartContent}>
            {order &&
              order?.GetByIdTransDetail &&
              order?.GetByIdTransDetail.length > 0 &&
              order?.GetByIdTransDetail.map((product, index) => {
                const correspondingProduct = productList.find(
                  (item) => item.PKID === product?.FKProdID
                );

                return (
                  <div className={`${classes.OrderSummaryBox} `} key={index}>
                    <div className="d-flex align-items-center ">
                      {correspondingProduct?.Images?.length > 0 &&
                      correspondingProduct?.Images ? (
                        <div className={`${classes.OrderImage}`}>
                          <img
                            variant="top"
                            src={correspondingProduct?.Images[0]}
                            alt="img"
                          />
                        </div>
                      ) : (
                        <div className={`${classes.OrderImage}`}>
                          <img src={noImg} alt="img" />
                        </div>
                      )}
                    </div>
                    <div
                      className={`${classes.Product} ${classes.productCont} mt-2 `}
                    >
                      <h6>
                        {orderFlag
                          ? product?.Product_name
                          : product?.NameToPrint}
                      </h6>
                      <p>
                        Qty : <span className="fw-bold">{product?.Qty}</span>{" "}
                      </p>
                      <div className={`${classes.ProductPrice}`}>
                        <span className={`${classes.Amount}`}>
                          {product?.Rate.toFixed(2)}
                        </span>
                        <span className={`${classes.Rupee} ms-1`}>₹</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className={`${classes.CheckoutBody}`}>
          <div className={`${classes.BillBody}`}>
            <div className={`${classes.Address}`}>
              <h5 className="mb-1">
                <span className="me-1">
                  <i className="bi bi-geo-alt-fill text-warning"></i>
                </span>
                Delivery Address
              </h5>
              {orderFlag ? (
                <>
                  <p>{order?.ShippingAddressRemark?.Street1}</p>
                  <p>{order?.ShippingAddressRemark?.Street2}</p>
                  <p>
                    {order?.ShippingAddressRemark?.City +
                      ", " +
                      order?.ShippingAddressRemark?.State +
                      ", " +
                      order?.ShippingAddressRemark?.Country}
                  </p>
                </>
              ) : (
                <>
                  {shippingAddressArray?.map((address, index) => (
                    <p key={index}>{address.ShippingAddress}</p>
                  ))}{" "}
                </>
              )}
            </div>

            {orderFlag ? (
              <>
                <p>Please wait for order confirmation from the admin</p>
              </>
            ) : (
              <>
                <div className={`${classes.Price}`}>
                  <h3>Order ID</h3>
                  <p>{order?.GRNo}</p>
                </div>
                <div className={`${classes.Price}`}>
                  <h3>Order Number</h3>
                  <p>{order?.Series + " " + order?.EntryNo}</p>
                </div>
                <div className={`${classes.Price}`}>
                  <h3>Order Date</h3>
                  <p>
                    {" "}
                    {new Date(order?.GRDate)
                      .toLocaleDateString("en-GB")
                      .replace(/\//g, "-")}
                  </p>
                </div>
                <div className={`${classes.TotalAmount}`}>
                  <h3>Status</h3>
                  <p> {order?.DeliveryStatus}</p>
                </div>
                <div className={`${classes.TotalAmount}`}>
                  <h3>Gross Amount</h3>
                  <p> {order?.GrossAmt.toFixed(2)}</p>
                </div>
                <div className={`${classes.TotalAmount}`}>
                  <h3>Tax Amount</h3>
                  <p> {order?.TaxAmt.toFixed(2)}</p>
                </div>
                <div className={`${classes.TotalAmount}`}>
                  <h3>Total Amount</h3>
                  <p> {order?.NetAmt.toFixed(2)}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;
